type logValueType = {
  by: string;
  at: string;
};

type SubtitleType = {
  type: string;
  value: logValueType | null;
};

type SectionColumn = {
  fields: Field[];
};

export interface Section {
  columns: SectionColumn[];
  placeholder: string | null;
  title: string;
  subtitle: SubtitleType | null;
}

export interface Field {
  inline: boolean;
  label: string;
  type: string;
  value: string | number | (string | number)[];
  highlight: boolean;
  copyable: boolean;
}

export enum CardFieldType {
  datetime = "datetime",
  date = "date",
  list = "list",
}
